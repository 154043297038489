
// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Routing
import { withRouter } from "react-router-dom";
// Vendor
import { langObject } from 'vendor/application';
import { showNotify, updateProjectsStatus } from 'vendor/application/disptach';
// Infrastructure
import { ProjectAPI } from 'domains/Project/infrastructure';
// Material
import { Button, Card, CardActions, TextField, Divider, FormControl, Select, MenuItem, CircularProgress } from '@material-ui/core';

class ProjectCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: {
                dialog_flow_id: "",
                lang: "es",
                credentials_content: "",
            },
            isCreating: false,
            lang: langObject()
        }
        this.createProject = this.createProject.bind(this);
    }

    updateElement(e, type) {
        const { project } = this.state;
        var obj = project;
        switch (type) {
            case "dialog_flow_id": obj.dialog_flow_id = e.target.value; break;
            case "lang": obj.lang = e.target.value; break;
            case "credentials": obj.credentials_content = e.target.value; break;
        }

        this.setState({ project: obj });
    }

    async createProject(e) {
        e.preventDefault();
        const { access } = this.props.data;
        this.setState({ isCreating : true });
        try {
            const obj = new ProjectAPI(null, access, this.props, this.state.project);
            await obj.post_project();
            this.setState({ isCreating : false });
            this.props.dispatch(updateProjectsStatus(true));
            this.props.getAllProjects();
            this.returnToList();

        } catch (err) {
            console.log('[v] err', err);
            var data = { message: err, severity: 'error' };
            this.props.dispatch(showNotify(data));
            this.setState({ isCreating : false });
        }
    }

    returnToList = (e) => {
        this.props.history.push('/projects');
    }

    render() {
        const { project, isCreating, lang } = this.state;
        return (
            <React.Fragment>
                <Card elevation={3} className="cen-action-card">
                    <div className="cen-border"></div>
                    <div className="cen-action-header">
                        <h1>{'Project create'}</h1>
                        <CardActions>
                            <Button className="cen-btn-cancel" variant="outlined" onClick={this.returnToList}>CANCEL</Button>
                            <Button className="cen-btn-create" variant="outlined" onClick={this.createProject}>
                                {
                                    isCreating ? (
                                        <CircularProgress size={21} />
                                    ) : (
                                            'CREATE'
                                        )
                                }
                            </Button>
                        </CardActions>
                    </div>
                    <div className="cen-action-body">
                        {/* Dialog Flow ID */}
                        <div className="cen-action-element">
                            <div>
                                <span>Dialog flow ID</span>
                                <span>Aquí viene la descripción del elemento, explicado y argumentado para que el usuario sepa que va aquí.</span>
                            </div>
                            <TextField
                                defaultValue={project.dialog_flow_id}
                                onChange={(e) => { this.updateElement(e, 'dialog_flow_id') }}
                            />
                        </div>
                        <Divider></Divider>
                        <div className="cen-action-element">
                            <div>
                                <span>Language</span>
                                <span>Aquí viene la descripción del elemento, explicado y argumentado para que el usuario sepa que va aquí.</span>
                            </div>
                            <FormControl>
                                <Select
                                    value={(project.lang) ? project.lang : 'es'}
                                    onChange={(e) => { this.updateElement(e, 'lang') }}
                                >
                                    {
                                        lang.map(function (i) {
                                            return (
                                                <MenuItem key={i} value={i}>{i}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <Divider></Divider>
                        <div className="cen-action-element-grid">
                            <div>
                                <span>Credentials</span>
                                <span>Aquí viene la descripción del elemento, explicado y argumentado para que el usuario sepa que va aquí.</span>
                            </div>
                            <TextField
                                multiline
                                defaultValue={project.credentials_content}
                                variant="outlined"
                                placeholder="copy and paste dialogflow credential file content"
                                onChange={(e) => { this.updateElement(e, 'credentials') }}
                            />
                        </div>
                    </div>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_project_create = connect(
    mapStateToProps
)(ProjectCreate);

export default withRouter(connect_project_create);