import { CustomLogin } from 'vendor/infrastructure/custom_requests/login';
import { updateSending } from 'vendor/application/disptach';
import $ from 'jquery';

export class CustomRequest {
    constructor(method, url, body, token, props) {
        this.base_url = process.env.REACT_APP_URL_BACK;
        this.method = method;
        this.url = url;
        this.body = body;
        this.token = token;
        this.props = props;
        this.count = 0;
    }

    async make_request() {
        try {
            return { response : await $.ajax({
                url: `${this.base_url}${this.url}`,
                type: this.method,
                data: JSON.stringify(this.body),
                headers: {
                    "Authorization": `Bearer ${this.token.access}`,
                    "Content-Type": "application/json"
                }
            }) , status : 200}
        } catch(error) {
            return { response : error , status : error.status }; 
        }
    }
    
    async action() {
        const first = await this.make_request();
        this.count++;
        if ( this.count > 3 ) {
            throw 'Too many access requests.'
        } else {
            switch ( first.status ) {
                case ( 200 ) :
                    this.props.dispatch(updateSending(false));
                    return first.response;
                case ( 401 ) : 
                    let request = new CustomLogin({refresh : this.token.refresh}, 'token/refresh', this.props);
                    var req = await request.make_request_refresh();
                    this.token = req;
                    return this.action();
                case ( 500 ) : 
                    this.props.dispatch(updateSending(false));
                    throw 'Error 500 Internal Server Error';
                default : 
                    this.props.dispatch(updateSending(false));
                    throw first.response;
            }
        }
    }
}