import { deleteAllCookies, getCookieByName, setCookieByName } from 'vendor/application';

/**
 * Este fichero contiene el reducer principal de nuestra aplicación.
 * Basándonos en los tipos de las acciones, se realizaran modificaciones
 * sobre el estado inicial
 */

var access = getCookieByName('d__ac');

// Estado inicial de nuestra aplicación
const initialState = {
    access: { access: '', refresh: '', isAccess: false, userId: '' },
    notify: { message: '', severity: '', isNotify : false },
    initApp: false,
    isSending: false,
    projects: {},
    layouts: {} ,
    updateProjects : false
  }
  
  /**
   * El reducer recibe el estado actual y la acción a ejecutar. Si el estado
   * no está definido, obtenemos el estado por defecto
   */

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_STATE': {
        return Object.assign({}, state)
      }

      case 'UPDATE_PROJECTS_STATUS': {
        return Object.assign({}, state, {
          updateProjects : action.data
        })
      }
      
      case 'UPDATE_ACCESS_STATUS': {
        setCookieByName(action.data, 'd__ac' );
        return Object.assign({}, state,{
          access : action.data
        })
      }

      case 'SHOW_NOTIFY': {
        return Object.assign({}, state,{
          notify : { message : action.data.message, severity : action.data.severity, isNotify : true }
        })
      }

      case 'HIDE_NOTIFY': {
        return Object.assign({}, state,{
          notify : { message : action.data.message, severity : action.data.severity, isNotify : false }
        })
      }

      case 'UPDATE_LAYOUTS': {
        return Object.assign({}, state,{
          layouts : action.data
        })
      }

      case 'UPDATE_SENDING': {
        return Object.assign({}, state,{
          isSending : action.data
        })
      }

      case 'UPDATE_PROJECTS': {
        return Object.assign({}, state,{
          projects : action.data
        })
      }

      case 'UPDATE_INIT_APP': {
        return Object.assign({}, state,{
          initApp : action.data
        })
      }

      case 'RESET_STATE': {
        deleteAllCookies();
        return initialState;
      }

      default: {
        if ( access ) { state = { ...state, access } }
        return state;
      }
    }
  }
  
  export default reducer;
  