import React from 'react';
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './reducers';
import Root from './domains/Root/application/root';
import './styles/index.scss';

const store = createStore(rootReducer);

render(
  <Provider store={store}>
    <Router>
      <Root path="/"/>
    </Router>
  </Provider>,
  document.getElementById("root"));