export const updateState = () => {
  return {
    type: 'UPDATE_STATE',
  }
}

export const resetState = () => {
  return {
    type: 'RESET_STATE',
  }
}

export const updateAccessStatus = data => {
  return {
    type: 'UPDATE_ACCESS_STATUS',
    data
  }
}

export const updateLayouts = data => {
  return {
    type: 'UPDATE_LAYOUTS',
    data
  }
}

export const updateProjectsStatus = data => {
  return {
    type: 'UPDATE_PROJECTS_STATUS',
    data
  }
}

export const updateActualLayout = data => {
  return {
    type: 'UPDATE_ACTUAL_LAYOUT',
    data
  }
}

export const updateSending = data => {
  return {
    type: 'UPDATE_SENDING',
    data
  }
}

export const updateProjects = data => {
  return {
    type: 'UPDATE_PROJECTS',
    data
  }
}

export const updateInitApp = data => {
  return {
    type: 'UPDATE_INIT_APP',
    data
  }
}

export const showNotify = data => {
  return {
    type: 'SHOW_NOTIFY',
    data
  }
}

export const hideNotify = data => {
  return {
    type: 'HIDE_NOTIFY',
    data
  }
}
