
// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Routing
import { withRouter } from 'react-router-dom';
// Components
import HeaderTable from 'components/Table/HeaderTable';
import BodyTable from 'components/Table/BodyTable';
import PaginationTable from 'components/Table/PaginationTable';
import FilterTable from 'components/Table/FilterTable';
// Icons
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: JSON.parse(JSON.stringify(this.props.data.projects)),
            arrayCompare: JSON.parse(JSON.stringify(this.props.data.projects)),
            arraySplit: [],
            headerData: ['Dialog Flow ID', 'Language', null]
        }
    }

    componentWillMount() {
        this.splitArray();
    }

    UNSAFE_componentWillReceiveProps = () => {
        const { updateProjects, projects } = this.props.data;
        if (updateProjects) {
            this.splitArray(0, projects);
        }
    }

    splitArray = (i, data) => {
        const { arrayCompare } = this.state;
        var array = JSON.parse(JSON.stringify(data ? data : arrayCompare));
        var arr = [];
        while(array.length) {
            arr.push(array.splice(0,10));
        }
        arr.length > 0 ?
        this.setState({ array: arr[i ? i : 0] , arraySplit : arr }) :
        this.setState({ array: arr , arraySplit : arr })
    }
 
    createElement = (e) => {
        e.preventDefault();
        this.props.history.push('/projects/create');
    }

    render() {
        const { headerData, array, arrayCompare, arraySplit } = this.state;
        const { projects } = this.props.data;
        return (
            <React.Fragment>
                <h1>Projects</h1>
                {
                    projects.length === 0 ? (
                        <span className="cen-noInfo">No projects found.</span>
                    ) : (
                            <React.Fragment>
                                {arrayCompare && arrayCompare.length > 0 && (
                                    <FilterTable update={this.splitArray} data={arrayCompare} type={'project'} />
                                )
                                }
                                {array.length > 0 ? (
                                    <React.Fragment>
                                        <HeaderTable data={headerData} />
                                        <BodyTable remove data={array} history={this.props.history} />
                                        {
                                            arrayCompare.length > 10 &&
                                            <PaginationTable update={this.splitArray} data={arraySplit} />
                                        }
                                    </React.Fragment>
                                ) : (
                                        <span className="cen-noInfo">No results found.</span>
                                    )}
                            </React.Fragment>
                        )
                }
                <IconButton className="cen-btn-new" aria-label="delete" onClick={this.createElement}>
                    <AddIcon fontSize="large" />
                </IconButton>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_project = connect(
    mapStateToProps
)(Project);

export default withRouter(connect_project);