// React
import React, { Component } from 'react';
// Material UI
import { IconButton, AppBar, Toolbar } from '@material-ui/core';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
// STYLES
import { withStyles } from '@material-ui/core/styles';

const useStyles = () => ({
    appBar: { backgroundColor : 'wheat' },
    img: { width: 130 },
    toolbar : {
        display : 'flex',
        justifyContent : 'space-between'
    }
});

class Header extends Component {
    
    render() {
        const { shutDown, classes } = this.props;
        return (
            <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <img className={classes.img} alt="bimyou-logo" src={process.env.PUBLIC_URL + '/centribal.png' ? process.env.PUBLIC_URL + '/centribal.png' : 'centribal.png'} />
              <IconButton
                color="inherit"
                aria-label="Power"
                edge="start"
                onClick={shutDown}
              >
                <PowerSettingsNewIcon fontSize="large"/>
              </IconButton>
            </Toolbar>
            </AppBar>
        );
    }
}

export default withStyles(useStyles)(Header);