// React
import React, { Component } from 'react';
// Material
import { Divider} from '@material-ui/core';

class BodyTable extends Component {
 
    editElement = (e, id) => {
        e.preventDefault();
        const { history } = this.props;
        this.props.history.push( `${history.location.pathname}/edit/${id}`)
    }

    render() {
        const { data } = this.props;
        return (
            <div className="cen-content-table">
            {
                data.map((el, i) => {
                    return (
                        <React.Fragment key={i}>
                            <div className="cen-select" onClick={(e) => { this.editElement(e, el.id) }}>
                                <span>{el.dialog_flow_id}</span>
                                <span>{el.lang}</span>
                                <div className="cen-action-button">
                                </div>
                            </div>
                            <Divider></Divider>
                        </React.Fragment>
                    )
                })
            }
            </div>
        );
    }
}

export default BodyTable;