// React
import React, { Component } from 'react';
// Material
import { IconButton } from '@material-ui/core';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

class PaginationTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actualPage : '',
            firstPage : '',
            lastPage : '',
            array : [],
            arrayClone : JSON.parse(JSON.stringify(this.props.data))
        }
    }
    
    componentDidMount = () => {
        this.filterData();
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { data } = this.props;
        if ( JSON.stringify(data) !== JSON.stringify(nextProps.data) ) {
            this.setState({ arrayClone : nextProps.data });
            this.filterData(nextProps.data);
        }
    }

    filterData = (data) => {
        const { arrayClone } = this.state;
        this.setState({ actualPage : 0, firstPage : 0, lastPage : data ? data.length - 1 : arrayClone.length - 1 }) 
    }

    pagination = (value) => {
        this.setState({ actualPage : value });
        this.props.update(value);
    }

    previousPage = (e) => {
        e.preventDefault();
        const { actualPage } = this.state;
        if ( actualPage !== 0 ) {
            this.pagination( actualPage - 1 );
        }
    }

    nextPage = (e) => {
        e.preventDefault();
        const { actualPage, lastPage } = this.state;
        if ( actualPage !== lastPage ) {
            this.pagination( actualPage + 1 );
        }
    }

    firstPage = (e) => {
        e.preventDefault();
        this.pagination(0);
    }

    lastPage = (e) => {
        e.preventDefault();
        const { lastPage } = this.state;
        this.pagination( lastPage );
    }

    render() {
        const { actualPage, firstPage, lastPage } = this.state;
        return (
            lastPage > 0 && (
                <div className="cen-pagination">
                    <IconButton disabled={ actualPage === firstPage } onClick={this.firstPage} aria-label="First Page">
                        <FirstPage fontSize="inherit" />
                    </IconButton>
                    <IconButton disabled={ actualPage === firstPage } onClick={this.previousPage} aria-label="Previous Page">
                        <KeyboardArrowLeft fontSize="inherit" />
                    </IconButton>
                    <span> {actualPage+1} of {lastPage+1} </span>
                    <IconButton disabled={ actualPage === lastPage } onClick={this.nextPage} aria-label="Next Page">
                        <KeyboardArrowRight fontSize="inherit" />
                    </IconButton>
                    <IconButton disabled={ actualPage === lastPage } onClick={this.lastPage} aria-label="Last Page">
                        <LastPage fontSize="inherit" />
                    </IconButton>
                </div>
            )
        );
    }
}

export default PaginationTable;