// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Routing
import { withRouter } from "react-router-dom";
// Components
import Header from 'components/Header';
// Domains
import RouteApp from 'domains/Routes/application/route_app';
import { ProjectAPI } from 'domains/Project/infrastructure';
// Material
import { CircularProgress } from '@material-ui/core';
// Vendor
import { updateInitApp, updateSending, resetState, showNotify, updateProjects, updateProjectsStatus } from 'vendor/application/disptach';

class App extends Component {
    constructor(props) {
        super(props);
        this.getAllProjects = this.getAllProjects.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(updateSending(false));
        this.getAllProjects();
    }

    async getAllProjects() {
        const { access } = this.props.data;
        if (access.isAccess) {
            try {
                let obj = new ProjectAPI(null, access, this.props);
                var projects = await obj.get_projects();

                this.props.dispatch(updateProjects(projects));
                this.props.dispatch(updateInitApp(true));
                this.props.dispatch(updateProjectsStatus(false));
            } catch (err) {
                var data = { message: err, severity: 'error' };
                this.props.dispatch(showNotify(data));
            }
        } else {
            this.props.dispatch(resetState())
        }
    }

    render() {
        const { initApp } = this.props.data;
        return (
            <div className="a-app">
                <div className="a-container">
                    {
                        initApp ?
                            <React.Fragment>
                                <Header shutDown={this.props.shutDown}/>
                                <RouteApp getAllProjects={this.getAllProjects}/>
                            </React.Fragment>
                            :
                            <div className="a-init">
                                <CircularProgress color="secondary" />
                            </div>
                    }

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_app = connect(
    mapStateToProps
)(App);

export default withRouter(connect_app);