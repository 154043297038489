// React
import { connect } from 'react-redux';
import React, { Component } from 'react';
// Routing
import { withRouter } from 'react-router-dom';
// INFRASTRUCTURE
import { CustomLogin } from 'vendor/infrastructure/custom_requests/login';
// ICONS
import { mdiAccount, mdiLock } from '@mdi/js';
import Icon from '@mdi/react';
// Material
import { Button, Card, CardActions, CircularProgress } from '@material-ui/core';
// Vendor
import { showNotify } from 'vendor/application/disptach';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading : false
        }
        this.user = "";
        this.passwd = "";

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(e) {
        e.preventDefault();
        const { user, passwd } = this;
        this.setState({ isLoading : true });
        try {
            let request = new CustomLogin({ username: user, password: passwd }, 'token', this.props);
            let login = await request.make_request_token();
            console.log('[v] LOGIN: ', login );
            var userId = this.getUserId(login.access);
            this.props.handleAccess(login, userId);
            this.setState({ isLoading : false });
            this.props.history.push('/');
        } catch (err) {
            console.log('[v]ERROR:  ', err );
            var data = { message: err, severity: 'error' };
            this.props.dispatch(showNotify(data));
            this.setState({ isLoading : false });
        }
    }

    getUserId(access) {
        var e = this.b64_to_utf8(access.split('.')[1]);
        return JSON.parse(e).user_id;
    }

    b64_to_utf8(str) {
        return decodeURIComponent(escape(window.atob(str)));
    }

    render() {
        const { isLoading } = this.state;
        return (
            <div className="cen-back">
                <span className="cen-title">DFcentribot</span>
                <Card elevation={3} className="cen-login">
                    <div className="cen-border"></div>
                    <div className="cen-login-content">
                        {/* NAME */}
                        <form>
                            <div className="display-center">
                                <input
                                    onChange={event => this.user = event.target.value}
                                    type="text"
                                    placeholder="Username"
                                />
                                <span className="cen-icons user"><Icon path={mdiAccount} size={'25px'} /></span>
                            </div>
                            <div className="display-center">
                                <input
                                    onChange={event => this.passwd = event.target.value}
                                    type="password"
                                    placeholder="Password"
                                />
                                <span className="cen-icons passwd"><Icon path={mdiLock} size={'25px'} /></span>
                            </div>
                            <CardActions>
                            <Button className="cen-btn-submit" type="submit" variant="outlined" onClick={this.handleSubmit}> 
                                {
                                    isLoading ? (
                                        <CircularProgress size={21} />
                                    ) : (
                                        'Submit'
                                    )
                                }
                            </Button>
                            </CardActions>
                        </form>
                    </div>
                </Card>
            </div>
            // <div className="cen-back">
            //     <span className="cen-title">DialogFlow LIST</span>
            //     <div className={`cen-login display-center`}>                
            //     <form>
            //         <input
            //             onChange={event => this.user = event.target.value }
            //             type="text"
            //             autoComplete="username"
            //             placeholder="Username"
            //             required
            //         />
            //         <span className="cen-icons user"><Icon path={mdiAccount} size={'25px'}/></span>
            //         <input
            //             onChange={event =>  this.passwd = event.target.value }
            //             type="password"
            //             autoComplete="current-password"
            //             placeholder="Password"
            //             required
            //         />
            //         <span className="cen-icons passwd"><Icon path={mdiLock} size={'25px'}/></span>
            //         <input className="btn" type="submit" onClick={this.handleSubmit} value="Submit"/>
            //     </form>
            //     </div>
            // </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_login = connect(
    mapStateToProps
)(Login);

export default withRouter(connect_login);