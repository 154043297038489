
// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Material
import { LinearProgress } from '@material-ui/core';
// Routing
import { withRouter, Route, Switch } from 'react-router-dom';
// Domain
import Project from 'domains/Project/application/project';
import ProjectEdit from 'domains/Project/application/project_edit';
import ProjectCreate from 'domains/Project/application/project_create';
import Redirect from 'domains/Redirect/application';
// Styles
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
    linearProgress: {
        position: 'fixed',
        top: 64,
        width: '100%',
        left: 0,
        zIndex: 1,
        background: 'dodgerblue',
        [theme.breakpoints.down('xs')]: {
            top: 56
        },
    }
});

class RouteApp extends Component {

    render() {
        const { classes } = this.props;
        const { isSending } = this.props.data;
        return (
            <div className={`bim-content`}>
                {
                    isSending && 
                    <LinearProgress className={classes.linearProgress}/>
                }
                <Switch>
                    <Route path="/projects" exact component={Project} />
                    <Route path="/projects/create" exact>
                        <ProjectCreate getAllProjects={this.props.getAllProjects}/>
                    </Route>
                    <Route path="/projects/edit/:id" exact>
                        <ProjectEdit getAllProjects={this.props.getAllProjects}/>
                    </Route>
                    <Route path="/*" component={Redirect} />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return { 
        data: state
    }
}

const connect_route_app = connect(
    mapStateToProps
  )(RouteApp);

export default withRouter(withStyles(useStyles)(connect_route_app));