// Vendor
import { updateAccessStatus } from 'vendor/application/disptach';
import $ from 'jquery';

export class CustomLogin {
    constructor(body, url, props) {
        this.base_url = process.env.REACT_APP_URL_BACK;
        this.url = url;
        this.body = body;
        this.props = props;
    }

    async make_request_token() {
        try {
            return await $.ajax({
                url: `${this.base_url}${this.url}`,
                type: 'POST',
                data: JSON.stringify(this.body),
                headers: {
                    "Content-Type": "application/json"
                }
            })
        } catch(error) {
            switch(error.status) {
                case 401 : 
                throw error.responseJSON.detail;
                case 400 :
                throw error.responseJSON.username[0];
            }
        }
    }

    async make_request_refresh() {
        const { access } = this.props.data;
        try {
            var result = await $.ajax({
                url: `${this.base_url}${this.url}`,
                type: 'POST',
                data: JSON.stringify(this.body),
                headers: {
                    "Content-Type": "application/json"
                }
            });
        } catch(error) {
            var result =  { response : error , status : error.status }; 
        }
        
        if ( !result ) {
            var data = { ...access, isAccess : false, userId : access.userId };
            this.props.dispatch(updateAccessStatus(data));
            throw `The session has expired, Log In again`;
        }
        var obj = result;
        var acc = access;
        acc.access = obj.access;
        var data = { ...acc, isAccess : true, userId : access.userId };
		this.props.dispatch(updateAccessStatus(data));
        return obj;
    }
}