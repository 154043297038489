
export const getCookieByName = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            var a = atob(c.substring(name.length, c.length));
            return JSON.parse(a);
        }
    }
    return false;
}

export const setCookieByName = (state, cname) => {
    var date = new Date();
    date.setTime(date.getTime()+(1*24*60*60*1000));
    var expires = "; path=/; expires="+date.toGMTString();
    var cookie = `${cname}=${btoa(JSON.stringify(state))}${expires}`;
    document.cookie = cookie;
}

export const deleteAllCookies = () => {
    const cookies = ['d__ac', 'd__ly'];
    for ( var cookie of cookies) {
        document.cookie = [`${cookie}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/;`].join('');
    }
}

export const langObject = () => {
    var str = ['es','ca','en','fr','pt','it','ES-es'];
    return str;
}

export const setCookieLayout = (state) => {
    var date = new Date();
    date.setTime(date.getTime()+(1*24*60*60*1000));
    var expires = "; expires="+date.toGMTString();
    var cookie = 'd__ly=' + JSON.stringify(state) + expires;
    document.cookie = cookie;
}

export const getCookieLayout = () => {
    var input = document.cookie.match('d__ly');
    if ( input ) {
        var result = input.input;
        var acc = result.split('d__ly=')[1];
        acc && (result = acc.split('path=/')[0]);
        return JSON.parse((result.replace(';', '')));
    }
    return null;
}

export const idExist = (id, array) => {
    for ( var x = 0; x < array.length; x++ ) {
        if ( array[x].id === id ) {
            return true;
        }
    } return false;
}

export const returnElementFromArray = (el, array, type) => {
    for ( var x = 0; x < array.length; x++ ) {
        if ( array[x][type] === el ) {
            return array[x];
        }
    } return false;
}

export const addParamsProjectValue = (array) => {
    for ( var x = 0; x < array.length; x++ ) {
        var str = array[x].name.replace(' ', '-');
        array[x].text = str.toLowerCase();
    }
    return array;
}