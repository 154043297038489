import React from 'react';
import {Button} from '@material-ui/core';
import {SnackbarProvider, useSnackbar} from 'notistack';
import './style.scss';

function Notify(e) {
    const {enqueueSnackbar} = useSnackbar();
    const handleClickVariant = () => () => {
        // Variant TYPES : be success, error, warning, info, or default
        var error = e.props,
            msg = error?.message?.responseJSON?.message ?? error.message;

        enqueueSnackbar(msg, {
            variant: e.props.severity,
            anchorOrigin: {vertical: 'top', horizontal: 'right'},
            autoHideDuration: 3000
        })
    };

    const handleClick = (el) => {
        if (e.props.isNotify && el) {
            el.click();
        }
    }

    return (
        <React.Fragment>
            <div className="snack-notify">
                <Button ref={handleClick} onClick={handleClickVariant()}>S</Button>
            </div>
        </React.Fragment>
    );
}

export default function IntegrationNotistack(e) {
    return (
        <SnackbarProvider maxSnack={1}>
            <Notify props={e.props}/>
        </SnackbarProvider>
    );
}
