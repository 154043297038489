// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Routing
import { withRouter, Switch, Route } from 'react-router-dom';
// Vendor
import { updateAccessStatus, resetState, hideNotify } from 'vendor/application/disptach';
// Containers
import Login from 'domains/Login/application';
import App from 'domains/App/application'; 
// Components
import IntegrationNotistack from 'components/Notify';

class Root extends Component {

    componentDidMount() {
        setTimeout(() => {
            this.redirect();
        }, 100);
    }

    componentWillReceiveProps(nextProps) {
        var notify = nextProps.data.notify;
        if ( notify.isNotify ) {
            var data = { message : notify.message, severity : notify.severity };
            this.props.dispatch(hideNotify(data));
        }
    }
    
    redirect() {
        const { history, location } = this.props;
        if ( !this.props.data.access.isAccess) {
            this.props.dispatch(resetState());
            history.push('/login');
        } else {
            if ( location.pathname === '/login') {
                history.push('/')
            } else {
                history.push(`${location.pathname}`)
            }
        }
    }

	handleAccess = (access, userId) => {
        var data = { ...access, isAccess : true, userId : userId }
		this.props.dispatch(updateAccessStatus(data));
    }
    
    shutDown = (e) => {
        e.preventDefault();
        const { history } = this.props;
        
		this.props.dispatch(resetState());
        history.push('/login');
    }

	render() {
        const { notify } = this.props.data;
        return (
            <React.Fragment>
                <IntegrationNotistack props={notify}/>
                    <Switch>
                        <Route path="/login" exact>
                            <Login state={this.props.data} handleAccess={this.handleAccess} />
                        </Route>
                        <Route path="/">
                            <App shutDown={this.shutDown} />
                        </Route>
                    </Switch>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return { 
        data: state
    }
}

const connect_root = connect(
    mapStateToProps
  )(Root);

export default withRouter(connect_root);