// React
import React, { Component } from 'react';

class HeaderTable extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { data } = this.props;
        return (
            <div className="cen-header-table">
                {
                    data.map((el, i) => {
                        return (
                            <span key={i}>{el}</span>
                        )
                    })
                }
            </div>
        );
    }
}

export default HeaderTable;