// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Routing
import { withRouter } from 'react-router-dom';

class Redirect extends Component {

    componentDidUpdate = () => {
        this.props.history.push('/projects');
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <h1>Redirecting</h1>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return { 
        data: state
    }
}

const connect_redirect = connect(
    mapStateToProps
  )(Redirect);

export default withRouter(connect_redirect);