// React
import React, { Component } from 'react';
// Material
import { TextField } from '@material-ui/core';

class FilterTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchString : ''
        }
    }

    searchOnArray = (e) => {
        e.preventDefault();
        const { data, update } = this.props;
        var string = e.target.value;
        var filter = data.filter(({ dialog_flow_id }) => dialog_flow_id.toLowerCase().includes(string.toLowerCase()));
        update(0, filter);
        this.setState({ searchString : e.target.value });
    }

    render() {
        return (
            <div className="cen-filter">
                <TextField
                    style={{ margin: 8, fontSize: 24, maxWidth: 400, width: '100%' }}
                    placeholder={`Filter by Dialog Flow ID`}
                    margin="normal"
                    onChange={this.searchOnArray}
                    value={this.state.searchString}
                />
            </div>
        );
    }
}

export default FilterTable;