
// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Routing
import { withRouter } from "react-router-dom";
// Vendor
import { showNotify, updateProjectsStatus } from 'vendor/application/disptach';
import { langObject } from 'vendor/application';
// Infrastructure 
import { ProjectAPI } from 'domains/Project/infrastructure';
// Material
import { Button, Card, CardActions, TextField, Divider, FormControl, Select, MenuItem, CircularProgress } from '@material-ui/core';

class ProjectEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: '',
            id: this.props.match.params.id,
            isSaving: false,
            lang: langObject()
        }
        this.getProject = this.getProject.bind(this);
        this.saveProject = this.saveProject.bind(this);
    }

    componentDidMount = () => {
        this.getProject();
    }

    async getProject() {
        const { access } = this.props.data;
        try {
            let obj = new ProjectAPI(this.props.match.params.id, access, this.props);
            let project = await obj.get_project_by_id();
            this.setState({ project: project });
        } catch (err) {
            this.errorRequest();
        }
    }

    updateElement(e, type) {
        const { project } = this.state;
        var obj = project;
        switch (type) {
            case "dialog_flow_id": obj.dialog_flow_id = e.target.value; break;
            case "lang": obj.lang = e.target.value; break;
            case "credentials": obj.credentials_content = e.target.value; break;
            case "centribot_project_id": obj.centribot_project_id = e.target.value; break;
        }

        this.setState({ project: obj });
    }

    errorRequest = () => {
        this.props.history.push('/projects');
        var data = { message: `The project doesn't exist.`, severity: 'error' };
        this.props.dispatch(showNotify(data));
    }

    returnToList = (e) => {
        const { history } = this.props;
        this.props.dispatch(updateProjectsStatus(true));
        this.props.getAllProjects();
        history.push('/projects');
    }

    async saveProject(e) {
        e.preventDefault();
        const { access } = this.props.data;
        this.setState({ isSaving : true });
        try {
            const obj = new ProjectAPI(this.state.project.id, access, this.props, this.state.project);
            await obj.put_project();
            var data = { message: 'The project was saved successfully.', severity: 'success' };
            this.props.dispatch(showNotify(data));
            this.setState({ isSaving : false });
        } catch (err) {
            var data = { message: err, severity: 'error' };
            this.props.dispatch(showNotify(data));
            this.setState({ isSaving : false });
        }
    }

    render() {
        const { project, isSaving, lang } = this.state;
        return (
            <React.Fragment>
                {project &&
                    <React.Fragment>
                        <Card elevation={3} className="cen-action-card">
                            <div className="cen-border"></div>
                            <div className="cen-action-header">
                                <h1>{'Project edit'}</h1>
                                <CardActions>
                                    <Button className="cen-btn-cancel" variant="outlined" onClick={this.returnToList}>CANCEL</Button>
                                    <Button className="cen-btn-create" variant="outlined" onClick={this.saveProject}>
                                        {
                                            isSaving ? (
                                                <CircularProgress size={21} />
                                            ) : (
                                                    'SAVE'
                                                )
                                        }
                                    </Button>
                                </CardActions>
                            </div>
                            <div className="cen-action-body">
                                {/* Dialog Flow ID */}
                                <div className="cen-action-element">
                                    <div>
                                        <span>Dialog flow ID</span>
                                        <span>Aquí viene la descripción del elemento, explicado y argumentado para que el usuario sepa que va aquí.</span>
                                    </div>
                                    <TextField
                                        defaultValue={project.dialog_flow_id}
                                        onChange={(e) => { this.updateElement(e, 'dialog_flow_id') }}
                                    />
                                </div>
                                <Divider></Divider>
                                <div className="cen-action-element">
                                    <div>
                                        <span>Centribot Project ID</span>
                                        <span>Aquí viene la descripción del elemento, explicado y argumentado para que el usuario sepa que va aquí.</span>
                                    </div>
                                    <TextField
                                        defaultValue={project.centribot_project_id}
                                        onChange={(e) => { this.updateElement(e, 'centribot_project_id') }}
                                    />
                                </div>
                                <Divider></Divider>
                                <div className="cen-action-element">
                                    <div>
                                        <span>Language</span>
                                        <span>Aquí viene la descripción del elemento, explicado y argumentado para que el usuario sepa que va aquí.</span>
                                    </div>
                                    <FormControl>
                                        <Select
                                            value={(project.lang) ? project.lang : 'es'}
                                            onChange={(e) => { this.updateElement(e, 'lang') }}
                                        >
                                            {
                                                lang.map(function (i) {
                                                    return (
                                                        <MenuItem key={i} value={i}>{i}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <Divider></Divider>
                                <div className="cen-action-element-grid">
                                    <div>
                                        <span>Credentials</span>
                                        <span>Aquí viene la descripción del elemento, explicado y argumentado para que el usuario sepa que va aquí.</span>
                                    </div>
                                    <TextField
                                        multiline
                                        defaultValue={project.credentials_content}
                                        variant="outlined"
                                        placeholder="copy and paste dialogflow credential file content"
                                        onChange={(e) => { this.updateElement(e, 'credentials') }}
                                    />
                                </div>
                            </div>
                        </Card>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_project_edit = connect(
    mapStateToProps
)(ProjectEdit);

export default withRouter(connect_project_edit);