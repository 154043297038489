import { CustomRequest } from 'vendor/infrastructure/custom_requests';
import { updateSending } from 'vendor/application/disptach';

export class ProjectAPI {
    constructor(projectID, token, props, body) {
        this.projectID = projectID;
        this.token = token;
        this.props = props;
        this.body = body;

        this.props.dispatch(updateSending(true));
    }

    async get_projects() {
        let request = new CustomRequest('GET', `projects`, this.body, this.token, this.props );
        var req = await request.action();
        return req;
    }

    async get_project_by_id() {
        let request = new CustomRequest('GET', `projects/${this.projectID}`, this.body, this.token, this.props );
        var req = await request.action();
        return req;
    }

    async get_contexts() {
        let request = new CustomRequest('GET', `projects/${this.projectID}/contexts`, this.body, this.token, this.props );
        var req = await request.action();
        return req;      
    };

    async post_project() {
        let request = new CustomRequest('POST', `projects`, this.body, this.token, this.props );
        var req = await request.action();
        return req;      
    };

    async put_project() {
        let request = new CustomRequest('PUT', `projects/${this.projectID}`, this.body, this.token, this.props );
        var req = await request.action();
        return req;      
    };
}